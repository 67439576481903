export const msalConfig = {
  auth: {
    clientId: '52a66014-e3df-4660-8c5f-a4959d2adb5c',
    authority:
      'https://login.microsoftonline.com/1f9b09b4-197c-4f1c-b0c5-571a6ccc96c8', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: 'https://landmark.staging.3kit.com',
    postLogoutRedirectUri: 'https://landmark.staging.3kit.com',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://52a66014-e3df-4660-8c5f-a4959d2adb5c/write'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
