import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from './Components/Login';
import ConfiguratorArea from './Components/ConfiguratorArea';
import Itemsummary from './Components/ItemSummary';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

function App() {
  const isAuthenticated = useIsAuthenticated(); // true; //
  const { summary } = useSelector((s) => s.threekit);

  // Prevent reload page
  useEffect(() => {
    console.log('isAuthenticated [App.js] = ', isAuthenticated);

    if (isAuthenticated) {
      window.addEventListener('beforeunload', function (e) {
        var confirmationMessage =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';

        (e || window.event).returnValue = confirmationMessage;
      });
    }
  }, [summary.length]);

  const [loading, setLoading] = useState(true);
  const { i18n, t } = useTranslation();

  document.body.dir = i18n.dir();

  document.addEventListener('DOMContentLoaded', function (event) {
    setLoading(false);
  });

  if (loading)
    return (
      <div id="outer_loader">
        <Spin tip="Loading..."></Spin>{' '}
      </div>
    );

  return (
    <div className="App">
      <div className="custom-container">
        <Switch>
          {isAuthenticated ? (
            <Route
              exact
              path="/configuratorarea"
              render={(props) => <ConfiguratorArea {...{ ...props }} />}
            />
          ) : (
            <Redirect to={{ pathname: '/', hash: window.location.hash }} />
          )}

          {summary.length ? (
            <Route exact path="/itemsummary">
              <Itemsummary />
            </Route>
          ) : (
            <Redirect to="/configuratorarea" />
          )}
        </Switch>

        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Login {...{ ...props, t }} />}
          />
        </Switch>
      </div>
    </div>
  );
}

export default App;
